<template>
  <div>
    <!--    <van-nav-bar title="预警信息" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--    </van-nav-bar>-->
    <van-tabs
      v-model="active"
      color="#5087ec"
      title-active-color="#5087ec"
      animated
      @change="changeTab"
    >
      <van-tab title="经营预警">
        <msg-card
          v-for="i in msgList.management"
          @toDetail="showMsgDetail"
          :key="i.NoticeId"
          :msg="i"
        >
        </msg-card>
        <van-empty
          description="暂无预警信息"
          v-if="msgList.management.length === 0"
        />
      </van-tab>
      <van-tab title="消防预警">
        <msg-card
          v-for="i in msgList.fire"
          @toDetail="showMsgDetail"
          :key="i.NoticeId"
          :msg="i.NoticeId"
        ></msg-card>
        <van-empty
          description="暂无预警信息"
          v-if="msgList.fire.length === 0"
        />
      </van-tab>
      <van-tab title="疫情预警">
        <msg-card
          v-for="i in msgList.epidemic"
          @toDetail="showMsgDetail"
          :key="i.NoticeId"
          :msg="i"
        ></msg-card>
        <van-empty
          description="暂无预警信息"
          v-if="msgList.epidemic.length === 0"
        />
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="showMsg"
      position="bottom"
      closeable
      :style="{ height: '90%' }"
    >
      <div style="box-sizing: border-box; padding: 0.2rem">
        <p class="title">{{ msgDetail.NoticeTitle }}</p>
        <p class="title2">
          来自：{{ msgDetail.Publisher }}
          <span>级别：{{ msgDetail.NoticeLevel }} </span>
        </p>
        <p>
          <span class="time">{{ msgDetail.NoticeTime }}</span>
        </p>
        <p>
          {{ msgDetail.NoticeContent }}
        </p>
        <p>
          <van-button type="info" @click="closeMsg" block size="small"
          >关闭
          </van-button
          >
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import MsgCard from '@views/cinemaAssistant/modules/warningMsg/msgCard'
import { zjApi as api } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'warningMsg',
  components: {
    MsgCard,
  },
  data() {
    return {
      active: 0,
      cinemaCode: '',
      showMsg: false,
      msgList: {
        management: [],
        fire: [],
        epidemic: [],
      },
      msgDetail: {
        IsRead: true,
        NoticeContent:
          '您管理的嘉兴海宁剧院近期票房数据有异常，请检查数据上报是否存在异常',
        Noticed: 12779,
        NoticeLevel: '省本级',
        NoticeTime: '2022-05-11 11:04',
        NoticeTitle: '影院经营异常预警',
        Publisher: '浙江省委宣传部电影处',
      },
    }
  },
  create() {
  },
  mounted() {
    this.cinemaCode = this.$route.query.id
    console.log(this.$route.query)
    this.active = parseInt(this.$route.query.t)
    this.getMsgList()
  },
  methods: {
    getMsgList() {
      return new Promise((resolve, reject) => {
        console.log(this.$store.state.userInfo.mobile)
        const mobile = this.$store.state.userInfo.mobile


        api
          .getPs({
            'UserCode': mobile,
            'CmdIndex': '101004',
            'cmdArgs': [
              this.cinemaCode,
              this.active,
            ],
          })
          .then((r) => {
            const { code, Message, result } = r
            switch (this.active) {
              case 0:
                this.msgList.management = result
                break
              case 1:
                this.msgList.fire = result
                break
              case 2:
                this.msgList.epidemic = result
                break
            }
            console.log(r)
          })
          .catch((e) => console.log({ e }))
      })
    },
    closeMsg() {
      this.showMsg = false
      this.getMsgList()
    },
    toTopPage() {
      window.history.back()
    },
    changeTab(tag) {
      console.log(tag)
      this.active = tag
      this.getMsgList()
    },
    showMsgDetail(id) {
      // alert(id);
      return new Promise((resolve, reject) => {
        const mobile = this.$store.state.userInfo.mobile
        const ps = `{"UserCode":"${mobile}","CmdIndex":"101005","cmdArgs":[${this.cinemaCode},${id}]}`
        api
          .getPs({ ps: ps })
          .then((r) => {
            const { Success, Message, data } = r
            if (Success) {
              this.msgDetail = data
              this.showMsg = true
            } else {
              Toast(Message)
            }
          })
          .catch((e) => console.log({ e }))
      })
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #5087ec;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}

.title {
  font-size: 16px;
  margin: 10px;
}

.title2 {
  margin: 10px;
  font-size: 14px;
}

.time {
  font-size: 12px;
  color: #0a4d77;
  margin: 10px;
}
</style>
